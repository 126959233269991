import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="max-w-6xl mx-auto px-4 w-full flex flex-wrap items-center justify-center">
        <h1>Apologies, but the page you're looking for doesn't exist.</h1>
        <p>
          Try going <a href="/">Home</a>
        </p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
